import { useParams, useLocation, useHistory } from 'react-router-dom';
import React, { useEffect, useState, useRef, useImperativeHandle } from 'react';
import queryString from 'query-string';
import PropTypes from 'prop-types';

import ExcercisePage from 'components/CanvasToolPlus/ExcercisePage';
import Result from 'components/CanvasToolPlus/ExcercisePage/Result';
import NotData from 'components/Error/NotData';
import Loading from 'components/Loading';
import { useQuery } from 'react-query';
import { getWorksheetData } from './api';
import { SlidePropsType } from 'modules/QuestionModule/SlideQuestions/PropsType';

const WorksheetSlide = ({ studentData }, ref) => {
  const { studentId, sessionId, assignmentId, attachmentId, classId } = studentData;

  const location = useLocation();

  const refSubmit = useRef({});

  const { takeExamTime, asrId } = queryString.parse(location.search);

  const [resultData, setResultData] = useState(null);

  const { data: file, isLoading } = useQuery(
    ['GET_WORKSHEET_DATA', studentId, attachmentId],
    () =>
      new Promise(async (resolve) => {
        try {
          const res = await getWorksheetData({
            attachmentId,
            sessionId,
            assignmentId: assignmentId,
            takeExamTime,
            userId: studentId,
            asrId,
          });
          if (res?.[0]?.id) {
            const { id, fileName, jsonData } = res[0];
            return resolve({ id, fileName, jsonData });
          }
          return resolve(undefined);
        } catch (error) {
          return resolve(undefined);
        }
      }),
    {
      enabled: !!studentId,
    }
  );

  const handleSubmit = async () => {
    const rs = await refSubmit.current.submit();
    if (rs && rs.info === 'ok') {
      const result = refSubmit.current.result();
      setResultData(result);
      return result;
    }
    return null;
  };

  const handleTryAgain = () => {
    refSubmit.current.tryAgain();
    setResultData(null);
  };

  // Sử dụng useImperativeHandle để expose function ra ngoài
  useImperativeHandle(ref, () => ({
    submit: () => {
      return handleSubmit();
    },
    tryAgain: () => {
      handleTryAgain();
    },
  }));

  if (isLoading) return <Loading />;
  if (!file) return <NotData />;

  return (
    <div>
      {!!resultData && <Result data={resultData} />}
      <div style={{ height: `calc(100vh - 130px - 90px - ${!!resultData ? 64 : 0}px)`, overflowY: 'auto' }}>
        {file ? (
          <ExcercisePage
            file={file}
            refSubmit={refSubmit}
            isVisibleResult={false}
            styles={{ height: '100vh - 50px' }}
            studentData={studentData}
          />
        ) : (
          <NotData />
        )}
      </div>
    </div>
  );
};

WorksheetSlide.propTypes = SlidePropsType;

export default React.forwardRef(WorksheetSlide);
